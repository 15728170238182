import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card-header border-0" }
const _hoisted_2 = { class: "card-title align-items-start flex-column m-0 w-75" }
const _hoisted_3 = {
  key: 0,
  class: "card-label fw-bolder fs-3 mb-2"
}
const _hoisted_4 = {
  key: 1,
  class: "breadcrumbs"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = {
  key: 0,
  class: "add-btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.$route.path != '/dashboard')
    ? (_openBlock(), _createBlock("div", {
        key: 0,
        class: ["card", `mb-${_ctx.margin}`]
      }, [
        _createVNode("div", _hoisted_1, [
          _createVNode("h3", _hoisted_2, [
            (_ctx.pageHeader?.title)
              ? (_openBlock(), _createBlock("span", _hoisted_3, _toDisplayString(_ctx.getTranslation(_ctx.pageHeader?.title)), 1))
              : _createCommentVNode("", true),
            (Object.entries(_ctx.pageHeader)?.length > 0)
              ? (_openBlock(), _createBlock("ul", _hoisted_4, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.pageHeader?.breadCrumbs, (item, index) => {
                    return (_openBlock(), _createBlock(_Fragment, { key: index }, [
                      (item.link && !(_ctx.$route.path.includes('/order/details') || _ctx.$route.path.includes('/quotation/details')))
                        ? (_openBlock(), _createBlock("li", _hoisted_5, [
                            _createVNode(_component_router_link, {
                              class: `${item.link == '#' ? 'not-allowed' : ''}`,
                              to: item.link
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getTranslation(item.name)), 1)
                              ]),
                              _: 2
                            }, 1032, ["class", "to"])
                          ]))
                        : (item.link && (_ctx.$route.path.includes('/order/details') || _ctx.$route.path.includes('/quotation/details')))
                          ? (_openBlock(), _createBlock("li", {
                              key: 1,
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.go(-1)))
                            }, [
                              _createVNode(_component_router_link, {
                                class: `${item.link == '#' ? 'not-allowed' : ''}`,
                                to: ""
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getTranslation(item.name)), 1)
                                ]),
                                _: 2
                              }, 1032, ["class"])
                            ]))
                          : (_openBlock(), _createBlock("li", _hoisted_6, _toDisplayString(_ctx.getTranslation(item.name)), 1))
                    ], 64))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode("div", null, [
            (_ctx.pageHeader?.actionButton && _ctx.$can(_ctx.pageHeader?.actionButton?.ability))
              ? (_openBlock(), _createBlock("div", _hoisted_7, [
                  (_ctx.pageHeader.actionButton?.button?.openModal == true)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: "#",
                        class: ["btn btn-sm", _ctx.pageHeader.actionButton?.button?.class],
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.pageAction(_ctx.pageHeader?.actionButton)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode("i", {
                            class: _ctx.pageHeader.actionButton?.button?.icon
                          }, null, 2)
                        ]),
                        _: 1
                      }, 8, ["class"]))
                    : (_openBlock(), _createBlock(_component_router_link, {
                        key: 1,
                        to: _ctx.pageHeader.actionButton?.button?.path,
                        class: ["btn btn-sm", _ctx.pageHeader.actionButton?.button?.class]
                      }, {
                        default: _withCtx(() => [
                          _createVNode("i", {
                            class: _ctx.pageHeader.actionButton?.button?.icon
                          }, null, 2)
                        ]),
                        _: 1
                      }, 8, ["to", "class"]))
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}