
import { defineComponent , computed} from 'vue';
import { footerWidthFluid } from '@/core/helpers/config';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'KTFooter',
    setup() {
		const store = useStore();
        const footer_bg_color = computed(() => {
			return store.state.AuthModule.footer_bg_color;
		});
        const footer_color = computed(() => {
			return store.state.AuthModule.footer_color;
		});
        return {
            footerWidthFluid,
            footer_bg_color,
            footer_color
        };
    }
});
